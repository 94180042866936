<template>
  <div>
    <div class="flex justify-space-between">
      <div class="box self-flex-end"><h2 class="mv-0">SETTINGS</h2></div>
      <div class="box"><el-button @click="$onCommandParams('settings')">BACK</el-button></div>
    </div>

    <el-divider class="mv-10 o-020"></el-divider>

    <div class="flex gaps m-10">
        <el-form label-position="left" label-width="260px" :model="form">
          <el-form-item label="Double session discount">
            <el-input-number
              size="medium"
              :controls="false"
              v-model="form.double"></el-input-number>
          </el-form-item>
          <el-form-item label="Block booking">
            <el-input-number
              size="medium"
              :controls="false"
              v-model="form.blockBooking"></el-input-number>
            <div class="hint-text">How many minutes before the start of the session the reservation is blocked. Negative number possible.</div>
          </el-form-item>
          <el-form-item label="Refund booking">
            <el-input-number
              size="medium"
              :controls="false"
              v-model="form.refundBooking"></el-input-number>
            <div class="hint-text">How many hours before the start of the session the refund is possible</div>
          </el-form-item>
          <el-form-item label="Edit booking">
            <el-input-number
              size="medium"
              :controls="false"
              v-model="form.editBooking"></el-input-number>
            <div class="hint-text">How many hours before the start of the session the edit is possible</div>
          </el-form-item>
          <el-form-item>
            <el-button size="medium" type="info" @click="save()">Save</el-button>
          </el-form-item>
        </el-form>
    </div>

    <el-divider class="mv-10 o-020"></el-divider>

    <div class="m-10">
        <el-form label-position="left" label-width="260px">
        <el-form-item label="POS name">
          <el-input size="medium" v-model="posName" style="width: 200px;"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button size="medium" type="info" @click="setPosName()">Set POS NAme</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div class="m-10">
        <el-form label-position="left" label-width="260px">
          <el-form-item label="Сursor is always on input">
            <el-switch
              @change="onCursorToogle"
              v-model="cursor"
              active-text="Recommended for POS and Laptop"
              inactive-text="Recommended for Tablet">
            </el-switch>
          </el-form-item>
        </el-form>
    </div>

    <el-divider class="mv-10 o-020"></el-divider>

    <div class="m-10">
      <el-button type="primary" disabled @click="updateDB()">Update DB</el-button>
      <span class="pl-16 accent-text">* Attention! Do not press if you don't know what you are doing</span>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'Double',

  data () {
    return {
      dataDouble: 0,
      form: {
        double: 0,
        refundBooking: 0,
        editBooking: 0,
        blockBooking: 0
      },
      cursor: true,
      posName: ''
    }
  },

  computed: {
    ...mapState({
      double: state => state.double.double
    }),
    posname () {
      return localStorage.getItem('posname') ? localStorage.getItem('posname') : ''
    }
  },

  methods: {
    ...mapActions(['setUpdate', 'changeDouble']),
    save () {
      this.changeDouble({
        double: this.form.double,
        blockBooking: this.form.blockBooking,
        refundBooking: this.form.refundBooking,
        editBooking: this.form.editBooking
      })
        .then(() => {
          this.$message({
            showClose: true,
            message: 'Settings save successfully',
            type: 'success'
          })
        })
    },
    setPosName () {
      localStorage.setItem('posname', this.posName)
    },
    onCursorToogle (val) {
      let cursor = JSON.parse(localStorage.getItem('cursor'))
      if (cursor !== null) {
        localStorage.setItem('cursor', JSON.stringify(val))
      } else {
        localStorage.setItem('cursor', 'true')
      }
    },
    updateDB () {
      this.setUpdate()
    }
  },
  mounted () {
    this.posName = this.posname
    this.form = Object.assign(this.form, this.double)
    let cursor = JSON.parse(localStorage.getItem('cursor'))
    this.cursor = cursor !== null ? cursor : true
  }
}
</script>

<style lang="scss">
</style>
